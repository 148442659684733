import {CardLink, CardType, CardTypeChildrenDef} from "../../types/CardType";
import {Accordion, Button, Form, Stack} from "react-bootstrap";
import {ArrayUtil, ObjectUtil} from "incomaker-react-ts-commons";
import {BsPencil, BsPlusCircle, BsTrash} from "react-icons/bs";
import {useState} from "react";

const DEFAULT_CHILDREN: CardTypeChildrenDef = {
	group_prefix: 'child',
	custom_col_birthday: 'birthday',
	custom_col_name: 'name',
	custom_col_sex: 'sex'
}

export type CardTypeFormProps = {
	cardType: CardType;
	onPropChanged: () => any;
};

export default function CardTypeForm({cardType, onPropChanged}: CardTypeFormProps) {
	const [originalEditingLink, setOriginalEditingLink] = useState<CardLink | null>(null);
	const [editingLink, setEditingLink] = useState<CardLink | null>(null);

	const addLink = (): void => {
		const link = {url: '', label: ''};
		setEditingLink(link);
	}

	const editLink = (l: CardLink): void => {
		setOriginalEditingLink(l);
		setEditingLink(ObjectUtil.clone(l));
	}

	const cancelLinkEdit = (): void => {
		setOriginalEditingLink(null);
		setEditingLink(null);
	}

	const saveLink = (): void => {
		if (editingLink === null) return;
		if (ObjectUtil.isEmpty(cardType.settings.links)) {
			cardType.settings.links = [];
		}
		cardType.settings.links = ArrayUtil.remove(cardType.settings.links, originalEditingLink);
		cardType.settings.links?.push(editingLink);
		setOriginalEditingLink(null);
		setEditingLink(null);
		onPropChanged();
	}

	const removeLink = (link: CardLink): void => {
		if (ObjectUtil.isEmpty(cardType.settings.links)) {
			return;
		}
		if (!window.confirm("Opravdu si přejete smazat tento odkaz?")) {
			return;
		}
		cardType.settings.links = ArrayUtil.remove(cardType.settings.links, link);
		onPropChanged();
	}

	return (
		<div className="card-editor-form">
			<Form>
				<Accordion defaultActiveKey="content">
					<Accordion.Item eventKey="content">
						<Accordion.Header>Obsah</Accordion.Header>
						<Accordion.Body>
							<Stack gap={2}>
								<Form.Group controlId="companyName">
									<Form.Label column={"sm"}>Název společnosti:</Form.Label>
									<Form.Control
										type="text"
										value={cardType.settings.company_name}
										onChange={(e) => {
											cardType.settings.company_name = e.target.value;
											onPropChanged();
										}}
									/>
								</Form.Group>
								<Form.Group controlId="programName">
									<Form.Label column={"sm"}>Název věrnostního programu:</Form.Label>
									<Form.Control
										type="text"
										value={cardType.settings.program_name}
										onChange={(e) => {
											cardType.settings.program_name = e.target.value;
											onPropChanged();
										}}
									/>
								</Form.Group>
								<Form.Group controlId="pointsLabel">
									<Form.Label column={"sm"}>Popisek stavu bodového konta:</Form.Label>
									<Form.Control
										type="text"
										value={cardType.settings.points_label}
										onChange={(e) => {
											cardType.settings.points_label = e.target.value;
											onPropChanged();
										}}
									/>
									<Form.Text muted>
										Použijte značku <strong>&#123;contactName&#125;</strong> pro vložení jména vlastníka karty.
									</Form.Text>
								</Form.Group>
								<Form.Group controlId="barcodeType">
									<Form.Label column={"sm"}>Typ čárového kódu:</Form.Label>
									<Form.Select
										value={cardType.settings.barcode_type}
										onChange={(e) => {
											cardType.settings.barcode_type = e.target.value;
											onPropChanged();
										}}
									>
										<option value="">Žádný</option>
										<option value="qr">QR Code</option>
										<option value="code128">Code128</option>
									</Form.Select>
								</Form.Group>
							</Stack>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="look">
						<Accordion.Header>Vzhled</Accordion.Header>
						<Accordion.Body>
							<Stack gap={2}>
								<Form.Group controlId="backgroundColor">
									<Form.Label column={"sm"}>Barva pozadí:</Form.Label>
									<Stack direction="horizontal" gap={2}>
										<Form.Control
											type="text"
											value={cardType.settings.background_color}
											onChange={(e) => {
												cardType.settings.background_color = e.target.value;
												onPropChanged();
											}}
										/>
										<Form.Control
											type="color"
											value={cardType.settings.background_color}
											onChange={(e) => {
												cardType.settings.background_color = e.target.value;
												onPropChanged();
											}}
										/>
									</Stack>
								</Form.Group>
								<Form.Group controlId="foregroundColor">
									<Form.Label column={"sm"}>Barva textu:</Form.Label>
									<Stack direction="horizontal" gap={2}>
										<Form.Control
											type="text"
											value={cardType.settings.foreground_color}
											onChange={(e) => {
												cardType.settings.foreground_color = e.target.value;
												onPropChanged();
											}}
										/>
										<Form.Control
											type="color"
											value={cardType.settings.foreground_color}
											onChange={(e) => {
												cardType.settings.foreground_color = e.target.value;
												onPropChanged();
											}}
										/>
									</Stack>
									<Form.Text muted>
										Má vliv jen pro Apple. Google Wallet zvolí barvu textu automaticky podle barvy pozadí.
									</Form.Text>
								</Form.Group>
								<Form.Group controlId="companyLogo">
									<Form.Label column={"sm"}>Logo společnosti:</Form.Label>
									<Form.Control
										type="text"
										value={cardType.settings.logo_image}
										onChange={(e) => {
											cardType.settings.logo_image = e.target.value;
											onPropChanged();
										}}
									/>
									<Form.Text muted>
										Malý obrázek, zpravidla v pravém horním rohu. Min. rozměry <strong>660 x 660</strong> pixelů,
										doporučený formát <strong>PNG</strong>, poměr stran <strong>1:1</strong>.
									</Form.Text>
								</Form.Group>
								<Form.Group controlId="mainImage">
									<Form.Label column={"sm"}>Hlavní obrázek:</Form.Label>
									<Form.Control
										type="text"
										value={cardType.settings.main_image}
										onChange={(e) => {
											cardType.settings.main_image = e.target.value;
											onPropChanged();
										}}
									/>
									<Form.Text muted>
										Malý obrázek, zpravidla v pravém horním rohu. Min. rozměry <strong>1032 x 336</strong> pixelů,
										doporučený formát <strong>PNG</strong>, poměr stran <strong>3:1</strong> nebo širší.
									</Form.Text>
								</Form.Group>
							</Stack>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="links">
						<Accordion.Header>Odkazy</Accordion.Header>
						<Accordion.Body>
							<Stack gap={2}>
								{
									ArrayUtil.notEmpty(cardType.settings.links) && cardType.settings.links?.map(
										(l: CardLink, index: number) =>
											<Stack direction="horizontal" gap={2} key={index}>
												<div className="w-100">{l.url}</div>
												<div>{l.label}</div>
												<Button title="Upravit odkaz" size="sm" onClick={() => editLink(l)}>
													<BsPencil/>
												</Button>
												<Button variant="danger" title="Smazat odkaz" size="sm" onClick={() => removeLink(l)}>
													<BsTrash/>
												</Button>
											</Stack>
									)
								}
							</Stack>
							<div className="border-top mt-2 pt-2">
								{
									ObjectUtil.isEmpty(editingLink) ?
										<div className="text-center">
											<Button onClick={addLink} size="sm">
												<Stack direction="horizontal" gap={2}>
													<BsPlusCircle/>
													Přidat odkaz
												</Stack>
											</Button>
										</div>
										: (
											<Stack gap={2}>
												<Form.Group controlId="linkLabel">
													<Stack direction="horizontal" gap={2}>
														<Form.Label column="sm">Popisek:</Form.Label>
														<Form.Control
															type="text"
															value={editingLink?.label}
															onChange={(e) => {
																// @ts-ignore
																editingLink.label = e.target.value;
																setEditingLink(ObjectUtil.clone(editingLink));
															}}
														/>
													</Stack>
												</Form.Group>
												<Form.Group controlId="linkUrl">
													<Stack direction="horizontal" gap={2}>
														<Form.Label column="sm">URL:</Form.Label>
														<Form.Control
															type="text"
															value={editingLink?.url}
															onChange={(e) => {
																// @ts-ignore
																editingLink.url = e.target.value;
																setEditingLink(ObjectUtil.clone(editingLink));
															}}
														/>
													</Stack>
												</Form.Group>
												<Stack direction="horizontal" className="text-center m-auto">
													<Button onClick={saveLink} size="sm">Uložit</Button>
													<Button onClick={cancelLinkEdit} size="sm" variant="link">Zrušit</Button>
												</Stack>
											</Stack>
										)
								}
							</div>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="children">
						<Accordion.Header>Děti</Accordion.Header>
						<Accordion.Body>
							<Stack gap={2}>
								<Form.Group controlId="useChildren">
									<Stack direction="horizontal">
										<Form.Label column={"sm"}>Uvádět na kartičce děti:</Form.Label>
										<Form.Check
											type="switch"
											checked={ObjectUtil.notEmpty(cardType.settings.children)}
											onChange={(e) => {
												cardType.settings.children = ObjectUtil.notEmpty(cardType.settings.children) ? undefined : DEFAULT_CHILDREN;
												onPropChanged();
											}}
										/>
									</Stack>
								</Form.Group>
								{
									ObjectUtil.notEmpty(cardType.settings.children) && (
										<Stack gap={2}>
											<small>
												Nastavení níže se týkají názvů custom fields, ze kterých se berou údaje o dětech zobrazené na kartičce.
											</small>
											<Form.Group controlId="childrenGroupPrefix">
												<Form.Label column={"sm"}>group_prefix:</Form.Label>
												<Form.Control
													type="text"
													value={cardType.settings.children?.group_prefix}
													onChange={(e) => {
														// @ts-ignore
														cardType.settings.children.group_prefix = e.target.value;
														onPropChanged();
													}}
												/>
											</Form.Group>
											<Form.Group controlId="childrenCustomColName">
												<Form.Label column={"sm"}>custom_col_name:</Form.Label>
												<Form.Control
													type="text"
													value={cardType.settings.children?.custom_col_name}
													onChange={(e) => {
														// @ts-ignore
														cardType.settings.children.custom_col_name = e.target.value;
														onPropChanged();
													}}
												/>
											</Form.Group>
											<Form.Group controlId="childrenCustomColBirthday">
												<Form.Label column={"sm"}>custom_col_birthday:</Form.Label>
												<Form.Control
													type="text"
													value={cardType.settings.children?.custom_col_birthday}
													onChange={(e) => {
														// @ts-ignore
														cardType.settings.children.custom_col_birthday = e.target.value;
														onPropChanged();
													}}
												/>
											</Form.Group>
											<Form.Group controlId="childrenCustomColSex">
												<Form.Label column={"sm"}>custom_col_sex:</Form.Label>
												<Form.Control
													type="text"
													value={cardType.settings.children?.custom_col_sex}
													onChange={(e) => {
														// @ts-ignore
														cardType.settings.children.custom_col_sex = e.target.value;
														onPropChanged();
													}}
												/>
											</Form.Group>
										</Stack>
									)
								}
							</Stack>
						</Accordion.Body>
					</Accordion.Item>
					<Accordion.Item eventKey="advanced">
						<Accordion.Header>Pokročilé</Accordion.Header>
						<Accordion.Body>
							<Stack gap={2}>
								<Form.Group controlId="systemName">
									<Stack direction="horizontal">
										<Form.Label column="sm">Systémový název:</Form.Label>
										<Form.Text>
											<strong>{cardType.suffix}</strong>
										</Form.Text>
									</Stack>
								</Form.Group>
								<Form.Group controlId="cardNumberCustomCol">
									<Form.Label column="sm">Název sloupce s číslem kartičky:</Form.Label>
									<Form.Control
										type="text"
										value={cardType.settings.card_number_custom_col}
										onChange={(e) => {
											cardType.settings.card_number_custom_col = e.target.value;
											onPropChanged();
										}}
									/>
								</Form.Group>
								<Form.Group controlId="pointsCustomCol">
									<Form.Label column="sm">Název sloupce s počtem bodů:</Form.Label>
									<Form.Control
										type="text"
										value={cardType.settings.points_custom_col}
										onChange={(e) => {
											cardType.settings.points_custom_col = e.target.value;
											onPropChanged();
										}}
									/>
								</Form.Group>
								<Form.Group controlId="googleIssuer">
									<Form.Label column="sm">Google Issuer ID:</Form.Label>
									<Form.Control
										type="text"
										value={cardType.settings.google_issuer_id}
										onChange={(e) => {
											cardType.settings.google_issuer_id = e.target.value;
											onPropChanged();
										}}
									/>
								</Form.Group>
								<Form.Group controlId="appleTeam">
									<Form.Label column="sm">Apple Team ID:</Form.Label>
									<Form.Control
										type="text"
										value={cardType.settings.apple_team_id}
										onChange={(e) => {
											cardType.settings.apple_team_id = e.target.value;
											onPropChanged();
										}}
									/>
								</Form.Group>
								<Form.Group controlId="applePassType">
									<Form.Label column="sm">Apple Pass Type:</Form.Label>
									<Form.Control
										type="text"
										value={cardType.settings.apple_pass_type_id}
										onChange={(e) => {
											cardType.settings.apple_pass_type_id = e.target.value;
											onPropChanged();
										}}
									/>
								</Form.Group>
							</Stack>
						</Accordion.Body>
					</Accordion.Item>
				</Accordion>
			</Form>
		</div>
	)
}
