import {OAuthSessionManager, RestClient, RestClientHeaders, SessionPayload, StringUtil} from "incomaker-react-ts-commons";
import conf from "../config/conf.json";
import {CardType} from "../types/CardType";
import {ServerInfo} from "../context/ServerInfoContext";

export class CardsRestClient extends RestClient {

	private sessionManager?: OAuthSessionManager;

	private serverInfo?: ServerInfo;

	private insecureClient: RestClient;

	constructor() {
		super(conf.API_URL);
		// rest client without OAuth headers
		this.insecureClient = new RestClient(conf.API_URL);
	}

	getServerInfo(): Promise<ServerInfo> {
		if (this.serverInfo !== undefined) {
			return Promise.resolve(this.serverInfo);
		}
		return this.insecureClient.getJson('server-info')
			.then((si: ServerInfo) => {
				this.serverInfo = si;
				return this.serverInfo;
			});
	}

	getOAuthServerUrl(): Promise<string> {
		return this.getServerInfo().then((si) => si.oauth_server_url);
	}

	getSessionManager(): Promise<OAuthSessionManager> {
		if (this.sessionManager !== undefined) {
			return Promise.resolve(this.sessionManager);
		}
		return this.getOAuthServerUrl()
			.then(
				(oauthUrl) => {
					this.sessionManager = new OAuthSessionManager(oauthUrl, 'loyalty-cards-frontend');
					return this.sessionManager;
				}
			);
	}

	initialize(sessionId: string): Promise<boolean> {
		return this.getSessionManager()
			.then(
				(sm) => {
					sm.initializeSessionId(sessionId);
					return true;
				});
	}

	getHeaders(): Promise<RestClientHeaders> {
		return this.getSessionManager()
			.then(sm => sm.getAccessToken())
			.then(
				(accessToken) => {
					return {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${accessToken}`
					}
				}
			);
	}

	getSession(): Promise<SessionPayload> {
		return this.getSessionManager()
			.then(sm => sm.getSession());
	}

	loadCardTypes(pluginId: number): Promise<Array<CardType>> {
		return this.getJson(`cards/types/${pluginId}`);
	}

	loadCardType(pluginId: number, suffix: string): Promise<CardType> {
		return this.getJson(`cards/types/${pluginId}/${suffix}`);
	}

	saveCardType(pluginId: number, ct: CardType): Promise<CardType> {
		if (StringUtil.isEmpty(ct.suffix)) {
			return this.postJson(`cards/types/${pluginId}`, ct);
		} else {
			return this.putJson(`cards/types/${pluginId}/${ct.suffix}`, ct);
		}
	}

	deleteCardType(pluginId: number, suffix: string): Promise<Response> {
		return this.del(`cards/types/${pluginId}/${suffix}`);
	}
}
