import React, {useContext, useState} from 'react';
import CardTypesList from "./list/CardTypesList";
import CardTypeEditor from "./editor/CardTypeEditor";
import Footer from "./Footer";
import {Alert, Stack} from 'react-bootstrap';
import {UserAlertsContext} from '../context/UserAlertsContext';
import {UserAlert} from 'incomaker-react-ts-commons';

function Main() {
	const [isDetail, setIsDetail] = useState<boolean>(false);
	const [cardTypeKeySuffix, setCardTypeKeySuffix] = useState<string | null>(null);
	const [renderedAlerts, setRenderedAlerts] = useState<UserAlert[]>([]);
	const userAlerts = useContext(UserAlertsContext);

	userAlerts.addOnChangeHandler(() => {
		setRenderedAlerts([...userAlerts.alerts]);
	});

	const openEditor = (suffix: string | null) => {
		setCardTypeKeySuffix(suffix);
		setIsDetail(true);
	}

	const closeEditor = () => {
		setIsDetail(false);
		setCardTypeKeySuffix(null);
	}

	return (
		<>
			<Stack direction="vertical">
				{
					renderedAlerts.map(
						(a, i) => (
							<Alert variant={a.type} key={i}>
								{a.message}
							</Alert>
						)
					)
				}
			</Stack>
			<main>
				{
					isDetail ?
						<CardTypeEditor
							suffix={cardTypeKeySuffix}
							onEditorClosed={closeEditor}
						/>
						: <CardTypesList
							onEditorRequested={openEditor}
						/>
				}
			</main>
			<Footer/>
		</>
	);
}

export default Main;
