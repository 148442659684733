import React, {useContext} from 'react';
import {AppUserDataContext} from "../context/AppUserDataContext";
import {ServerInfoContext} from "../context/ServerInfoContext";

function Footer() {
	const serverInfo = useContext(ServerInfoContext);
	const appUserData = useContext(AppUserDataContext);

	if (serverInfo && !serverInfo.debug_mode) {
		return <></>
	}

	return (
		<footer className="small text-muted border-top my-3 py-2 d-flex align-items-center justify-content-between">
			<div>
				{
					serverInfo == null ? 'No server' : `Version: ${serverInfo.version}, debug: ${serverInfo.debug_mode}`
				}
			</div>
			<div>
				{
					appUserData == null ? 'Not logged in' : `User: ${appUserData.userName}, plugin: ${appUserData.pluginId}`
				}
			</div>
		</footer>
	);
}

export default Footer;
