import {Button, Stack} from "react-bootstrap";
import CardTypePreview from "./CardTypePreview";
import CardTypeForm from "./CardTypeForm";
import {useContext, useEffect, useState} from "react";
import {CardType} from "../../types/CardType";
import {ObjectUtil, StringUtil} from "incomaker-react-ts-commons";
import {BsArrowLeft, BsFillSaveFill, BsTrash} from "react-icons/bs";
import Loading from "../controls/Loading";
import {AppUserDataContext} from "../../context/AppUserDataContext";
import {UserAlertsContext} from "../../context/UserAlertsContext";
import {CardsRestClientContext} from "../../context/CardsRestClientContext";

export type CardTypeEditorProps = {
	suffix?: string | null;
	onEditorClosed: () => any
};

export default function CardTypeEditor({suffix, onEditorClosed}: CardTypeEditorProps) {
	const [cardType, setCardType] = useState<CardType | null>(null);
	const appUserData = useContext(AppUserDataContext);
	const userAlerts = useContext(UserAlertsContext);
	const restClient = useContext(CardsRestClientContext);

	useEffect(() => {
		if (!appUserData) {
			throw new Error("No user is logged in!");
		}
		if (StringUtil.isEmpty(suffix)) {
			setCardType({
				suffix: '',
				settings: {
					google_issuer_id: '',
					apple_team_id: '',
					apple_pass_type_id: 'pass.com.incomaker.passbook',
					company_name: '',
					program_name: '',
					points_label: '',
					logo_image: '',
					main_image: '',
					background_color: '',
					foreground_color: '',
					points_custom_col: 'bonus_points',
					card_number_custom_col: 'loyalty_card',
					barcode_type: '',
					links: []
				}
			});
			return;
		}
		restClient.loadCardType(appUserData.pluginId, String(suffix))
			.then((ct) => setCardType(ct))
			.catch((e: Error) => userAlerts.err(`${e.cause}: ${e.message}`));
	}, [appUserData, suffix]);

	if (!appUserData) {
		return <span>No user is logged in!</span>
	}

	if (cardType === null) {
		return <Loading/>
	}

	const saveCardType = () => {
		userAlerts.reset();
		restClient
			.saveCardType(appUserData.pluginId, cardType)
			.then(onEditorClosed)
			.catch((e: Error) => userAlerts.err(`${e.cause}: ${e.message}`));
	}

	const deleteCardType = () => {
		if (window.confirm("Opravdu si přejete smazat tuto kartičku?")) {
			restClient
				.deleteCardType(appUserData.pluginId, cardType.suffix)
				.then(onEditorClosed)
				.catch((e: Error) => userAlerts.err(`${e.cause}: ${e.message}`));
		}
	}

	return (
		<div className="card-type-editor">
			<Stack direction="horizontal">
				<Button onClick={onEditorClosed} variant="link">
					<Stack direction="horizontal" gap={1}>
						<BsArrowLeft/>
						<span>Zpět</span>
					</Stack>
				</Button>
			</Stack>
			<Stack direction="horizontal" className="align-items-start overflow-hidden">
				<CardTypeForm cardType={cardType} onPropChanged={() => setCardType(ObjectUtil.clone(cardType))}/>
				<CardTypePreview cardType={cardType}/>
			</Stack>
			<div className="py-2 mt-2 border-top">
				<Stack direction="horizontal" gap={2}>
					<Button className="d-flex w-100" onClick={saveCardType}>
						<Stack direction="horizontal" gap={2} className="m-auto">
							<BsFillSaveFill/>
							Uložit
						</Stack>
					</Button>
					<Button className="d-block" onClick={deleteCardType} variant="danger">
						<Stack direction="horizontal" gap={2}>
							<BsTrash/>
							Smazat
						</Stack>
					</Button>
				</Stack>
			</div>
		</div>
	)
}
