import {useContext, useEffect, useState} from "react";
import {Alert, Container, Stack} from "react-bootstrap";
import Main from "./Main";
import {SessionPayload, StringUtil} from "incomaker-react-ts-commons";
import {AppUserData, AppUserDataContext} from "../context/AppUserDataContext";
import Loading from "./controls/Loading";
import {CardsRestClientContext} from "../context/CardsRestClientContext";
import {ServerInfo, ServerInfoContext} from "../context/ServerInfoContext";

function getSessionIdFromUrl(): string | null {
	const up = new URLSearchParams(document.location.search);
	return up.get('sessionId');
}

export default function App() {
	const restClient = useContext(CardsRestClientContext);
	const [appUserData, setAppUserData] = useState<AppUserData | null>(null);
	const [serverInfo, setServerInfo] = useState<ServerInfo | null>(null);
	const [loginErrorMessage, setLoginErrorMessage] = useState<string | null>(null);

	useEffect(() => {
		const sessionId = getSessionIdFromUrl();
		if (StringUtil.isEmpty(sessionId)) {
			setLoginErrorMessage("No session ID provided!");
			return;
		} else {
			restClient.getServerInfo()
				.then(setServerInfo)
				.then(() => restClient.initialize(String(sessionId)))
				.then(() => restClient.getSession())
				.then((session: SessionPayload) => {
					setAppUserData({
						userName: session.user_name,
						pluginId: session.plugin_id
					});
				})
				.catch((e: Error) => {
					setLoginErrorMessage(String(e));
				});
		}
	}, []);

	return (
		<Container fluid>
			{
				(appUserData === null) ? (
					<div className="pt-3">
						<Stack direction="vertical" className="align-items-center">
							<div>Přihlašuji se...</div>
							<Loading/>
							{
								loginErrorMessage && <Alert variant="danger">{loginErrorMessage}</Alert>
							}
						</Stack>
					</div>
				) : (
					<ServerInfoContext.Provider value={serverInfo}>
						<AppUserDataContext.Provider value={appUserData}>
							<Main/>
						</AppUserDataContext.Provider>
					</ServerInfoContext.Provider>
				)
			}
		</Container>
	)
}
