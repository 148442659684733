import React, {useContext, useEffect, useState} from 'react';
import {CardType} from "../../types/CardType";
import {Button, Card, Stack} from 'react-bootstrap';
import Loading from "../controls/Loading";
import {UserAlertsContext} from '../../context/UserAlertsContext';
import {AppUserDataContext} from '../../context/AppUserDataContext';
import {CardsRestClientContext} from '../../context/CardsRestClientContext';

export type CardTypesListProps = {
	onEditorRequested: (suffix: string | null) => any
};

function CardTypesList({onEditorRequested}: CardTypesListProps) {
	const [list, setList] = useState<Array<CardType> | null>(null);
	const appUserData = useContext(AppUserDataContext);
	const userAlerts = useContext(UserAlertsContext);
	const restClient = useContext(CardsRestClientContext);

	useEffect(() => {
		if (!appUserData) {
			throw new Error("No user is logged in!");
		}
		restClient.loadCardTypes(appUserData.pluginId)
			.then((l) => setList(l))
			.catch((e: Error) => userAlerts.err(`${e.cause}: ${e.message}`));
	}, [appUserData]);

	if (!appUserData) {
		return <span>No user is logged in!</span>
	}

	if (list === null) return <Loading/>;

	return (
		<Stack gap={3}>
			<div>
				{
					(list.length === 0) ? (
						<div>Zatím nemáte vytvořenou žádnou kartičku</div>
					) : (
						<div className="d-flex flex-row gap-3">
							{
								list.map(
									(ct: CardType) => (
										<div>
											<Card
												key={ct.suffix}
												className="card-list-preview"
												style={{
													backgroundColor: ct.settings.background_color,
													color: ct.settings.foreground_color
												}}
												onClick={() => onEditorRequested(ct.suffix)}
											>
												<Card.Body>
													<Stack direction="horizontal" className="gap-3 align-items-center h-100">
														<div>
															<img src={ct.settings.logo_image} alt="company-logo"/>
														</div>
														<div>
															<h3>{ct.settings.program_name}</h3>
														</div>
													</Stack>
												</Card.Body>
											</Card>
											<div className="text-center"><small>{ct.suffix}</small></div>
										</div>
									)
								)
							}
						</div>
					)
				}
			</div>
			<Stack direction="horizontal">
				<Button onClick={() => onEditorRequested(null)}>+ Nová kartička</Button>
			</Stack>
		</Stack>
	);
}

export default CardTypesList;
